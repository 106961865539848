
import { Component, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action } from "vuex-class";
import { IOrder, IOrderPrintRequest } from "@/types/order";
import { Order, OrderPrintRequest } from "@/models/order";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // pdf
  @Action("pdf/userOrderHomePDF")
  public printOrderPdf!: (data: {
    params: IOrderPrintRequest;
    order_no: string;
  }) => Promise<boolean>;

  //Prop Watch
  @Prop({ default: new Order() })
  public order!: IOrder;

  // fields
  public row_length = 0;
  public composing = false;
  public order_remark_rows = ["", "", "", "", "", "", "", ""];
  public order_remark_row_max_length = 92;
  public product_description_row_max_length = 66;
  public product_code_row_max_length = 255;
  public rev_no_row_max_length = 14;
  public dialog = false;
  public valid = true;
  public print_params: IOrderPrintRequest = new OrderPrintRequest();

  // 印刷ダイアログを開く
  public async created() {
    this.print_params.createOrderPrintRequest(this.order);
    this.rowCount();
    if (!this.order.warehouse_id) {
      this.order_remark_rows[0] = this.order.destination || "";
    }
    if (this.order.remark) {
      this.order.warehouse_id
        ? (this.order_remark_rows[0] = this.order.remark)
        : (this.order_remark_rows[1] = this.order.remark);
    }
  }

  // 印刷ダイアログを閉じる
  public printClose() {
    this.print_params.products.splice(0);
    this.print_params.rev_no = "";
    this.print_params.order_remark = "";
    this.order_remark_rows = ["", "", "", "", "", "", "", ""];
    this.$emit("po_home_dialog", false);
  }

  // 発注書発行
  public async getPrint() {
    this.print_params.order_remark = this.order_remark_rows.join("\n");

    await this.printOrderPdf({
      params: this.print_params,
      order_no: this.order.order_no
    });
  }

  // 印刷の行数カウント
  public rowCount() {
    let length_sum = this.print_params.products.length;
    this.print_params.products.forEach(product => {
      length_sum += product.product_rows.length;
    });
    this.row_length = length_sum;
  }

  // 印刷行を増やす
  public addRowBtn(index: number) {
    this.print_params.products[index].product_rows.push({
      description: ""
    });
  }

  // 印刷行を減らす
  public deleteRowBtn(product_index: number, row_index: number) {
    this.print_params.products[product_index].product_rows.splice(row_index, 1);
  }

  // 備考/コメントの文字数制限
  public handleKeydown(e: KeyboardEvent, index: number) {
    if (this.composing) return; // IME対応(日本語変換中のEnterを止める)

    if (e.metaKey && (e.code == "KeyA" || "KeyC" || "KeyX")) return; //コピー、切り取り、全選択の場合は下記処理をスルー

    const rowCount = this.inputCounter(this.order_remark_rows[index]);

    if (
      rowCount >= this.order_remark_row_max_length &&
      this.keyCodeDetermine(e.code)
    ) {
      // テキストエリアからフォーカスを外す
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }

  // 消費税額計算
  public tax_calc(val: IOrder) {
    return this.order.currency_type != 1
      ? Number((val.subtotal * (val.tax_rate / 100)).toFixed(4)).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 4
          }
        )
      : Number(
          (val.subtotal * (val.tax_rate / 100)).toFixed(2)
        ).toLocaleString();
  }
}
